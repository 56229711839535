@import "/var/www/nuernberger/project/nuernberger/web/src/theme/_variables.scss";@import "/var/www/nuernberger/project/nuernberger/web/src/dashboard/scss/dashboard.scss";
.t1-xl {
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
}

.t1 {
  font-weight: 500;
  font-size: 1.25rem;
  color: #071C3B;
}

.t2 {
  font-weight: 500;
  font-size: 1rem;
  color: #071C3B;
}

.subtitle {
  font-weight: 500;
  font-size: 1.125rem;
  color: #071C3B;
}

.t3 {
  font-size: 0.875rem;
  line-height: 19px;
}

.t3-light {
  font-size: 0.875rem;
  font-weight: 400;
}

.t4 {
  font-size: 0.75rem;
  font-weight: 400;
}

.button {
  padding: 11.5px 16px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 6px;
  cursor: pointer;
}

.button-disabled {
  color: #acb0b8;
  background-color: #f3f5f9;
  border: none;
  cursor: not-allowed;
}

.button-blue {
  background-color: #3a9efb;
  color: #fff;
  border: none;
}

.button-red {
  background-color: #f72a2a;
  color: #fff;
  border: none;
}

.button-grey {
  background-color: #F3F5F9;
  color: #071C3B;
  border: none;
}

.button-file-out-red-bottom-rounded {
  padding: 8px 16px;
  border: 1px solid #d9534f;
  color: #d9534f;
  background-color: white;
  width: 171px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.button-out-blue {
  padding: 10.5px 16px;
  border: 1px solid #3a9efb;
  color: #3a9efb;
  background: #fff;
}

.marketplace .btn-primary {
  border: none;
}

.button-left-nav {
  width: 100%;
  border-radius: 6px;
  border: none;
  padding: 13px 12px;
  text-align: left;
  background-color: white;
  font-size: 0.875rem;
  color: #acb0b8;
  cursor: pointer;
  transition: background-color 0.5s ease-in-out;
}

.button-left-nav:hover {
  background-color: #f3f5f9;
  transition: background-color 0.5s ease-in-out;
}

.input-range {
  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }

  .react-datepicker__header__dropdown {
    padding: 10px 0
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range {
    background-color: #2C55D3;

    &:hover {
      background-color: #2C55D3;
    }
  }

  .react-datepicker__day--today {
    background: white;
    color: black;
    font-size: .8rem;
  }

  .react-datepicker__day--in-range {
    background-color: #2C55D3;
  }

  .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--today) {
    background: #2C55D3;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: #E6E7EB;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    margin-left: 25px;
    top: 5px;
  }

  .react-datepicker__month-option,
  .react-datepicker__year-option {
    background-color: white;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__month-option--selected,
  .react-datepicker__year-option--selected {
    display: none;
  }


}

.button-left-nav-active {
  background: #f3f5f9;
  transition: background-color 0.5s ease-in-out;
  font-weight: 500;
  color: #000;
}

.button-small {
  padding: 6px 12px;
}

.button-fullwidth {
  width: 100%;
}

.button-large {
  min-width: 100px;
}

.button-copy-to-clipboard {
  padding: 0 16px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.button-rounded-one-char {
  background-color: #D8ECFE;
  color: #3A9EFB;
  border: none;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  cursor: pointer;
}

.numeric-stepper-container {
  display: flex;
  gap: 5px;
  margin-right: 16px;
  align-items: center;
}

.status-button {
  border-radius: 6px;
  width: fit-content;
  padding: 4px 8px;
  min-height: 16px;
  font-weight: 500;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.status-button-success {
  background: #f1faf4;
  color: #4dc273;
}

.status-button-pending {
  background: #fef7eb;
  color: #ef9001;
}

.status-button-blue {
  background: #F5FAFF;
  color: #3A9EFB;
}

.status-button-error {
  background: #fdece9;
  color: #ff5739;
}

.status-button-grey {
  background: #F3F4F7;
  color: #6D7F9B;
}

.button-copy-to-clipboard-with-text {
  gap: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: #3a9efb;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.left-nav-group-title {
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  color: #747c88;
  text-transform: uppercase;
  margin-bottom: 20px;
}


.input {
  border: 1px solid #d1d5dd;
  box-sizing: border-box;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-size: 14px;
  padding-left: 12px;
  color: black;
}

.input-with-icon-container {
  position: relative;
  display: flex;
}

.input-with-icon {
  padding-left: 42px;
}

.input-disabled {
  background: #f3f5f9;
  color: #acb0b8;
}

.input-checkbox-container {
  display: flex;
}

.input-checkbox {
  color: #3c4858;
}

.input-checkbox-text {
  color: #3c4858;
}

.input-checkbox-subtext {
  padding-top: 2px;
  color: #747c88;
  font-weight: 300;
}

.rm-file-default-style {
  input[type="file"] {
    display: none !important;
  }
}

.input-file-out-blue-bottom-rounded {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid #3a9efb;
  color: #3a9efb;
  background-color: white;
  font-size: 15px;
  width: 167px;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.input-small {
  width: 50px;
}

.input-search {
  display: flex;
  width: 320px;
  border: 1px solid #d1d5dd;
  border-radius: 8px;
}

.input-search-icon {
  position: absolute;
  margin-top: 11px;
  margin-left: 16px;
}

.input-search-text {
  padding-left: 26px !important;
  height: 40px;
  width: 290px;
  font-size: 14px;
}

.input-numeric-stepper {
  width: 50px;
  text-align: center;
  padding-right: 12px;
}

.input-hours {
  display: grid;
  grid-template-columns: 10fr 3fr 10fr;
  align-items: flex-start;
  width: 145px;
  align-items: center;
  margin-right: 5px;

  svg {
    display: none;
  }

  .MuiSelect-root,
  .MuiSelect-select,
  .MuiSelect-selectMenu,
  .MuiInputBase-input {
    width: 34px;
  }
}

.input-hours-hypen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.input-hours-error {
  width: 200%;
  color: #ff5739;
  font-size: 15px;
  margin-bottom: 14px;
}

.numeric-stepper-placeholder {
  color: #3C4858;
  font-size: 14px;
  font-weight: 400;
}

.input-group {
  margin-bottom: 14px;
  width: 100%;

  .MuiSelect-select.MuiSelect-select {
    border: 1px solid #d1d5dd;
    border-radius: 8px;
    height: 25px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 15px;
    font-family: Inter, sans-serif;
  }

  .MuiSelect-select:focus {
    background-color: white;
    color: black;
  }

  .MuiInput-underline::before {
    border: none;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border: none;
  }

  .MuiInput-underline::after {
    border: none;
  }

  .MuiSelect-icon {
    right: 15px;
  }

  .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    padding-bottom: 0;
  }

  input[type="checkbox"] {
    transform: scale(1.3);
    margin-right: 15px !important;
    filter: grayscale(0.7);
  }
}

.MuiFormLabel-root {
  font-size: 14px !important;
}

.MuiInputBase-input {
  font-size: 14px !important;
}

.MuiOutlinedInput-root {
  border-radius: 8px !important;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 15px;
}

/* stylelint-disable declaration-block-no-shorthand-property-overrides */
.react-tel-input .phone-input {
  width: 100% !important;
  height: 40px !important;
  border: 1px solid #d1d5dd !important;
  border-top-right-radius: 9px !important;
  border-bottom-right-radius: 9px !important;
  border-radius: 9px !important;
}

/* stylelint-enable declaration-block-no-shorthand-property-overrides */

.react-tel-input .phone-input:focus {
  outline: 1px solid #275DC5 !important;
  box-shadow: 0 0 0 2px #275DC5 !important;
}

.react-tel-input .flag-dropdown {
  background-color: white !important;
  border: 1px solid #d1d5dd !important;
  border-right: none !important;
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
  height: 40px !important;
  z-index: 4;
}

select {
  background: url("data:image/svg+xml,<svg height='15px' width='15px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M0.505074 0.505074C0.778441 0.231707 1.22166 0.231707 1.49502 0.505074L5.00005 4.0101L8.50507 0.505074C8.77844 0.231707 9.22166 0.231707 9.49502 0.505074C9.76839 0.778441 9.76839 1.22166 9.49502 1.49502L5.49502 5.49502C5.22166 5.76839 4.77844 5.76839 4.50507 5.49502L0.505074 1.49502C0.231707 1.22166 0.231707 0.778441 0.505074 0.505074Z'/></svg>") no-repeat;
  background-position: calc(100% - 0.5rem) calc(100% - 0.4rem) !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}

.text-area-medium {
  border: 1px solid #d1d5dd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  padding-left: 12px;
  color: black;
  height: 80px;
  padding-top: 10px;
  line-height: 150%;
}

.additional-input {
  color: #3a9efb;
  font-size: 14px;
  margin-top: -6px;
  margin-bottom: 20px;
  cursor: pointer;
}

.additional-input-title {
  color: #647184;
  padding-top: 22px;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
}

.additional-input-title-pts {
  color: #647184;
  padding-top: 4px;
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
}

.placeholder {
  font-size: 12px;
  line-height: 24px;
}

.rct_panel-input {
  color: #15161a;
  font-size: 14px;
  border: 1px solid #d1d5dd;
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  margin-top: 5px;
  padding-left: 10px;
}


.rct_panel-double-input_container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.rct_panel-relative_container {
  position: relative;
}

.rtc_panel-select {
  appearance: none;
  background: transparent;
  width: 100%;
}

.rct_panel-select_icon {
  position: absolute;
  right: 15px;
  top: 45px;
  z-index: -1;
}

.rct_panel-label {
  font-size: 12px;
  color: #747c88;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.rct_panel_checkbox-label {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #3C4858
}

.rct_panel-textarea {
  height: 110px;
  padding-top: 10px;
}

.rct_panel-time_container {
  display: flex;
  flex-direction: row;
  width: 200px;
  flex-grow: 1;
}

.date-input_container {
  width: 50%;
}

.date-range-container {
  display: flex;
  font-size: 19px;
  gap: 10px;
  align-items: center;
}

.date-range-container-single {
  font-size: 12px;
  color: #747c88;
  font-weight: 400;
  display: flex;
  flex-direction: column;

  .date-range-input {
    color: #15161a;
    font-size: 14px;
    border: 1px solid #d1d5dd;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    padding-left: 33px;
    width: 130px;
  }
}

.date-input-icon-container {
  position: absolute;
  left: 10px;
  top: 12px;
  z-index: 1;
}

.start-time_container {
  position: relative;
  width: 100%;
}

.start-time {
  border-radius: 6px 0 0 6px !important;
  border-right: 0 !important;
  padding-left: 35px !important;
  width: 100%;
}

.start-time_icon {
  position: absolute;
  z-index: 1;
  top: 17px;
  left: 5px;
  margin-left: 5px;
}

.reset-date-range {
  width: 13px;
}

.time-select {
  position: absolute;
  top: 73px;
  width: 100%;
  padding: 8px 0;
  box-sizing: border-box;
  z-index: 303;
  background-color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 12%);
  border-radius: 6px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #3c4858;
}

.react-timeslot {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 16px;
  box-sizing: border-box;

  &:hover {
    background-color: #f3f5f9;
  }
}

.middle-dash {
  border-radius: 0;
  border-top: 1px solid #d1d5dd;
  border-bottom: 1px solid #d1d5dd;
  width: 20px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f5f9;
  flex-shrink: 0;
}

.end-time {
  border-radius: 0 6px 6px 0 !important;
  border-left: 0 !important;
  width: 100%;
}

.start-timepicker,
.end-timepicker {
  table {
    td {
      border-top: none;
      color: #15161a;
    }
  }
}

.start-timepicker {
  width: 55%;
}

.end-timepicker {
  width: 45%;
}

.timepicker-icon {
  font-size: 10px;
  color: #747c88;
}

.link {
  color: #3a9efb;
  cursor: pointer;
}

.text-with-link {
  display: flex;
  gap: 8px;
}

.switch-with-text-texts {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.MuiCircularProgress-colorPrimary {
  color: #3a9efb !important;
}

.loader-app {
  position: absolute;
  width: 100vw;
  height: 90vh;
  display: flex;
  padding-top: 25vh;
  justify-content: center;
  z-index: 1100;
}

.loader-section {
  display: flex;
  padding-top: 25vh;
  justify-content: center;
  min-height: 100vh;
}

.loader-list {
  display: flex;
  padding-top: 10vh;
  justify-content: center;
  min-height: 100vh;
}

.loader-ptxs {
  display: flex;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loader-center {
  display: flex;
  width: 100%;
  justify-content: center;
}

.loader-modal {
  display: flex;
  width: 100%;
  justify-content: center;
  height: 280px;
  padding-top: 50px;
}

.loader-right-modal {
  display: flex;
  padding-top: 10vh;
  height: calc(100vh - 50px);
  background-color: white;
  overflow: hidden;
  position: absolute;
  width: 90%;
  justify-content: center;
}

.loader-auth-right-banner-displayed {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20vh;
}

.loader-auth-right-banner-not-displayed {
  width: 100vw;
  height: calc(100vh - 56px);
  position: absolute;
  top: 56px;
  left: 0;
  display: flex;
  justify-content: center;
  background: white;
  padding-top: 20vh;
}

.inline-nav-item {
  cursor: pointer;
  white-space: nowrap;
}

.inline-nav-item-active {
  color: #3a9efb;
  border-bottom: 2px solid #3a9efb;
  padding-bottom: 15px;
}

.inline-nav-hr {
  border: 1px solid #eaecf2;
  margin-top: -2px;
  margin-bottom: 24px;
}

.avatar-initials {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d8ecfe;
  color: #3b9efa;
  text-align: center;
  text-transform: uppercase;
}

.avatar-initials-m {
  width: 32px;
  height: 32px;
  font-size: 14px;
}

.avatar-initials-l {
  width: 48px;
  height: 48px;
  font-size: 18.38px;
}

.avatar-initials-xl {
  width: 81px;
  height: 81px;
  font-size: 28px;
}

.round-selected-button {
  border-radius: 50%;
  width: 6px;
  height: 6px;
  background: white;
  border: 5px solid #3A9EFB;
  cursor: pointer;
}

.round-unselected-button {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid #D1D5DB;
  cursor: pointer;
}

.list-item-contact {
  display: flex;
  align-items: center;
  gap: 15px;
  color: rgb(7, 7, 7);
}

.dropdown-custom-option {
  color: #3A9EFB;
  padding-left: 30px;
  padding-top: 12px;
  cursor: pointer;
  padding-bottom: 12px;
}

.MuiMenu-list {
  .MuiCheckbox-colorSecondary,
  .Mui-checked {
    font-size: 14px;

    svg {
      color: #838E9D;
    }
  }
}

@media (max-width: 900px) {
  .input {
    border: 1px solid #d1d5dd;
    box-sizing: border-box;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    font-size: 14px;
    padding-left: 12px;
    color: black;

    &.input-with-icon {
      padding-left: 42px;
    }
  }

  .input-numeric-stepper {
    width: 40px;
  }

  .input-search-text {
    width: fit-content;
  }

  .input-search-icon {
    position: absolute;
    margin-top: 8px;
    margin-left: 15px;
  }

  .text-area-medium {
    height: 120px;
  }

  .input-search {
    display: flex;
    width: 100%;
    border: 1px solid grey;
    border-radius: 8px;
  }

  .loader-modal {
    padding-top: 150px;
  }
}
