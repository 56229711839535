@import "../../layout/scss/colors";
@import "../../layout/scss/buttons";

$left-side-background-color: white;
$left-side-color: #666;
$logotype-background-color: white;

.content-wrapper {
  background-color: $background-color;
}

body.skin-blue .main-sidebar,
body.skin-blue .left-side {
  background-color: $left-side-background-color;

  .sidebar-toggle:hover {
    background-color: $hover-color !important;
  }

  a{
    color: $left-side-color;
    &:hover,
    &:active,
    &:focus{
      color: darken($left-side-color,20%);
      background-color: darken($left-side-background-color, 5%);
      border-color: $primary-color;
    }
  }
  .sidebar-menu > li > a,
  .sidebar-menu > li.dropdown > a{
    color: $left-side-color;
    background-color: $left-side-background-color;
    &:hover,
    &:active,
    &:focus{
      color: darken($left-side-color,20%);
      background-color: lighten($left-side-background-color,50%);
      border-left-color: $primary-color;
    }
  }
  .sidebar-menu > li.active > a{
    color: darken($left-side-color,20%);
    background-color: darken($left-side-background-color,5%);
    border-left-color: $primary-color;
    &:hover,
    &:active,
    &:focus {
      color: darken($left-side-color, 20%);
    }
  }

  .sidebar-menu>li>.treeview-menu {
    background-color: darken($left-side-background-color,5%) !important;
    color: $left-side-color;
  }

  .sidebar-menu>li>.treeview-menu .active a {
    color: darken($left-side-color, 50%);
  }

  .dropdown-menu{
    background-color: white;
    border-color: #DDD;
    a,
    a:hover,
    a:active,
    a:focus{
      background-color: #EEE !important;
      color: black !important;
    }
  }
}

.skin-blue header.main-header .navbar{
  background-color: $primary-color;
}
.skin-blue .user-panel>.info, .skin-blue .user-panel>.info>a{
  color: $left-side-color;
}

.skin-blue .main-header .logo {
  background-color: white !important;
}

.skin-blue .main-header .logo:hover, .skin-blue .main-header .logo{
  background-color: $logotype-background-color;
  height: 53px;
}

.avatar{background-color: white;}

.btn-invert {
  border-width: 2px !important;
}

.btn-default{
  background-color: transparent;
  border-color: $primary-color;
  color: darken($primary-color,20%) !important;
  &:hover,
  &:active,
  &:focus{
    background-color: transparent;
    color: darken($primary-color,20%) !important;
  }
}

.btn-primary {
  background-color: $primary-color !important;
  color: $primary-front-color !important;
}

.btn-info {
  background-color: $info-color;
}

.btn-success {
  background-color: $success-color;
}

.btn-danger {
  background-color: $failure-color;
}

.btn {
  border-radius: 0;
  border-width: 0 !important;

  &:hover,
  &:active,
  &:focus{
    color: $hover-front-color !important;
    background-color: $hover-color !important;
    opacity: 1;
    border-width: 0;
  }
}

.progress-bar {
  background-color: $primary-color !important;
  border-color: darken($primary-color,10%) !important;
  color: lighten($primary-color,70%) !important;
}

.fc-toolbar .fc-today-button{
  background-color: $primary-color !important;
  border-color: $primary-color !important;
  color: white;
  &:hover,
  &:active,
  &:focus{
    background-color: darken($primary-color,5%) !important;
    border-color: darken($primary-color,5%) !important;
  }
}


.media.sell .conditions {
  color: darken($primary-color,20%) !important;
}

a{
  color: #333;
  &:hover,
  &:active,
  &:focus{
    color: #111;
  }
}

.bg-info{
  background-color: transparent;
}

.media.sell i {
  color: $primary-color;
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: $primary-color;
}

.fa-video {
  color: $primary-color !important;
}

.alert.alert-info {
  background-color: $info-color !important;
}

.logo {
  height: 100%;
}

.bg-green {
  background-color: $success-color !important;
}