@import "colors";

/* Buttons */
.btn.btn-primary, .btn.btn-default, .btn.btn-invert {
  background-color: $primary-color !important;
  color: $primary-front-color !important;
  border: 0;
}
/*
.btn-invert {
  background-color: white !important;
  border-color: $primary-color !important;
  color: $primary-color !important;
}
*/

.btn.btn-warning {
  background-color: $warn-color;
  color: $primary-front-color !important;
}

.btn.btn-info {
  background-color: $info-color !important;
  color: $primary-front-color;
}

.btn {
  border-radius: 0;
  border-width: 0;

  &:hover,
  &:active,
  &:focus{
    color: $hover-front-color !important;
    background-color: $hover-color !important;
    border-color: darken($primary-color, 5%) !important;
    opacity: 1;
  }
}

.btn.survey-button {
  &.yes, &.no {
    color: white !important;
    background-color: $primary-color !important;
    &:hover,
    &:active,
    &:focus {
      background-color: $hover-color !important;
    }
  }
}

.fpt-box2 button.btn-default:hover {
  background-color: $hover-color !important;
}