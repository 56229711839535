$primary-color: #003273;
$primary-front-color: white;
$secondary-color: #159DD9;
$background-color: #EEEEEE;
$success-color: #21916C;
$failure-color: #D6405F;
$info-color: $secondary-color;
$warn-color: #FDAB48;
$hover-color: #159DD9;
$hover-front-color: white;

.hans {
  color: black;
}
